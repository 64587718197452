import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Dropdown are typically used when you have more than 3 items to choose from with flexile trigger object component. They’re used for navigation or command menus, where an action is initiated based on the selection. Dropdown allows for complex functionality menu items that can’t be accomplished with Select.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/dropdown/guideline-1.svg",
      "alt": "dropdown guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Trigger :`}</strong>{` Object component (Button, List, Avatar, Etc) as a trigger to open the container item`}</li>
      <li parentName="ol"><strong parentName="li">{`Container Item :`}</strong>{` Modals or body of list item option .`}</li>
      <li parentName="ol"><strong parentName="li">{`Item Option :`}</strong>{` The item should always have a label that clearly to describes the action or option that it represents.`}
        <div className="divi" />
      </li>
    </ol>
    <h2>{`Usage`}</h2>
    <p>{`Dropdown presents a list of menu items that a user can choose to perform an action with. This component includes a menu trigger (a button, avatar or other component) and a menu items that are shown upon actioning the trigger. A menu item can be used to perform an action, a page navigation or show a sub menu. Each menu item can only perform a single action and please do not add additional actionable elements inside a menu item, they will not be keyboard accessible.`}</p>
    <p><strong parentName="p">{`Common places dropdown appear :`}</strong>{` Navigation bar, filter, etc`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Displaying a list of actions, options, or links. Usually displays 3 or more options</li>
      <li> Allowing complex functionality that a select component can't accomplish</li>
      <li> Taking immediate action or navigating users to another view</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        When there are less than 8 items in the list, and there is space to display all options. Consider group of radio
        or checkbox instead.
      </li>
      <li> When it is desirable to filter a long list of options.</li>
      <li>
        Displaying a list of actions or options using the browser's native select functionality. Use select component
        instead.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When the user is focused on a menu trigger, the following keyboard interactions apply. Enter and space open the menu and select the current menu item, up and down arrows move the user between the menu items and disabled menu items, separators, and group labels are never focused.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Consistent item option with icon indicator when external links" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/dropdown/guideline-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Inconsistent item option with icon and description" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/dropdown/guideline-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className='divi' />
    <h2>{`Content`}</h2>
    <p>{`When creating Dropdown :`}</p>
    <ul>
      <li parentName="ul">{`People navigate menus and choose menu items based on their labels, so it’s important that they’re accurate and informative.`}</li>
      <li parentName="ul">{`In general, use sentence case and write concise labels that clearly indicate the purpose of the selection.`}</li>
      <li parentName="ul">{`For action menu items, use verbs and verb phrases to describe the action that occurs when the item is chosen. For example, “Move”, “Log time”, or “Hide epic labels”.`}</li>
      <li parentName="ul">{`In most cases, links should be nouns. For example, Profile or Keyboard shortcuts.`}</li>
      <li parentName="ul">{`Exclude articles in menu items. For example, use “Add flag” instead of “Add a flag”.`}</li>
      <li parentName="ul">{`Keep menu items to a single line of text.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      